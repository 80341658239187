.mat-dialog-container{
	font-family: 'Poppins' !important;
    border-radius: 5px !important;

	.modal-header{
		background: $du-primary-clr !important;
		padding: 10px 15px !important;
		line-height: initial !important;
		border-radius: 5px 5px 0 0 !important;
		text-transform: none;

		&.warning-header{
		background: $model-warning-header-bg !important;
		}

		.title{
			color: $white-text !important;
			font-size: $xmedium-font-size !important;
			display: block;
			text-transform: none;
			text-align: center;
		}

		.close {
			opacity: 1;
			line-height: 20px !important;
			padding: 0 !important;
			cursor: pointer;
			color: #fff !important;
			margin: 0 !important;
			font-size: 20px !important;
		}
	}
	
	.mat-dialog-content {
		margin: 0px !important;
		padding: 20px 15px;
		min-height: 180px;
		h4 {
			margin-bottom: 0 !important;
		}
		&::ng-deep.mat-dialog-container{
			resize: both;
			overflow: auto !important;
			background: $bg-white;
			font-family: 'Poppins' !important;
		}
	}
	
	.mat-dialog-actions {
		padding: 20px;
		margin-bottom: 0;
	}
	
	.mat-form-field-infix {
		border-top: 0px !important;
	}
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
     padding: 0px !important;
}
.du-modal-container{
	.du-modal-popup{
		border-radius: 5px !important;
		border: 1px solid purple !important;
		padding: 0 !important;
		font-family: "poppins" !important;
		.swal2-header{
			&.du-modal-header{
				padding: 10px 15px !important;
				line-height: initial !important;
				border-radius: 5px 5px 0 0 !important;
				text-transform: none;
				padding: 0 !important;
    			height: 47px !important;
				width: 100%;
				text-align: center;
				&.du-modal-alert{
					background: $du-model-alert-header-bg !important;
				}

				&.du-modal-error{
					background: $du-model-error-header-bg !important;
				}

				.swal2-title{
					color: $white-text !important;
					font-size: $xmedium-font-size !important;
					display: block;
					text-transform: none;
					margin: 0 !important;
					text-align: center;
					padding: 8px 0 !important;
	                font-family: "poppins" !important;

				}
			}
		}
		.swal2-title{
		font-family: "poppins" !important;
		font-weight: 500;
		}
		.swal2-actions {
			margin: 0px 0px 20px 0px !important;
		}
		.swal2-html-container {
			z-index: 1;
			justify-content: center;
			margin: 1em 0.6em 1em !important;
			padding: 0;
			overflow: auto;
			color: inherit;
			font-size: 1em;
			font-weight: 500;
			line-height: normal;
			text-align: center;
			word-wrap: break-word;
			word-break: break-word;
		}

	}
}

.du-custom-modalbox{
	.mat-dialog-container{
		.modal-header{
			background: $du-model-alert-header-bg !important;
	
			&.warning-header{
			background: $model-warning-header-bg !important;
			}
		}

		.mat-dialog-content{
			min-height: auto;
		}
	}
}