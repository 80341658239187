mat-flat-button {
    min-width: 45px !important;
}

.custom-mat-btn {
    &:disabled {
        @include disableItem;
    }

    &:hover:disabled {
        background-color: transparent !important;
        color: inherit !important;
    }
}

.btn {
    cursor: pointer;

    &.disabled,
    &:disabled {
        @include disableItem;
    }
}

.custom-btn {
    @include xmlargeIcon;
    background-color: transparent !important;
    position: relative;
    float: left;
    border: 1px solid transparent;
}

.btn-align {
    display: flex;
    justify-content: center;
    align-items: center;
}

.du-sm-btn-group {
    min-width: 50px;
}

.du-btn-group {
    border: 0 solid $du-group-btn-bg;
    background: $du-group-btn-bg !important;
    border-radius: 5px;
    margin: 0 auto;
    height: 44px;
    // max-width: 302px;
    // min-width: 50px;
    float: left;

    button {
        &:first-child {
            border-radius: 5px 0 0 5px !important;

            &:last-child {
                border-radius: 5px !important;
            }
        }

        &:last-child {
            border-radius: 0 5px 5px 0 !important;
        }
    }

    .custom-btn {
        margin-top: 0;
        border: 1px solid transparent;
        border-right: 1px solid #3e4852 !important;
        padding: 0 10px;
        width: 50px;
        height: 44px;

        &:last-child {
            border-right: none !important;
        }

    }
}

.du-create-btn {
    background: transparent !important;
    color: $white !important;

    .mat-icon {
        font-weight: 700;
    }

    &:hover {
        color: $white !important;
        background: $du-add-btn-clr !important;
        height: 44px;
    }

    &[disabled] {
        opacity: 0.5;

        &:hover {
            background: transparent !important;
            color: $white !important;
        }
    }
}

.du-save-btn {
    background: transparent !important;
    color: $white !important;

    &:hover {
        color: $white !important;
        background: $du-save-btn-clr !important;
        height: 44px;
    }

    &[disabled] {
        background-color: transparent !important;
        color: $white !important;

        &:hover {
            background-color: transparent !important;
            color: $white !important;
        }
    }
}

.du-clone-btn {
    background: transparent !important;
    color: $white !important;

    .mat-icon {
        font-weight: 700;
    }

    &:hover {
        color: $white !important;
        background: $du-clone-btn-clr !important;
        height: 44px;
    }

    &[disabled] {
        opacity: 0.5;

        &:hover {
            background: transparent !important;
            color: $white !important;
        }
    }
}

.du-delete-btn {
    background: transparent !important;
    color: $white !important;

    .mat-icon {
        font-weight: 700;
    }

    &:hover {
        color: $white !important;
        background: $du-delete-btn-clr !important;
        height: 44px;
    }

    &[disabled] {
        opacity: 0.5;

        &:hover {
            background: transparent !important;
            color: $white !important;
        }
    }
}

.du-nextPrev-btn {
    background: transparent !important;
    color: $white !important;

    .mat-icon {
        font-weight: 700;
    }

    &:hover {
        color: $white !important;
        background: $du-nextPrev-btn-clr !important;
        height: 44px;
    }

    &[disabled] {
        opacity: 0.5;

        &:hover {
            background: transparent !important;
            color: $white !important;
        }
    }
}

.custom-icon {
    font-size: 20px !important;
    line-height: 30px !important;
    @include mediumIcon;
    margin-left: 0px;
    margin-top: 0px;
}

.custom-fa-icon {
    font-size: 20px !important;
    line-height: 27px !important;
}

.custom-icon2 {
    font-size: 24px !important;
    transform: scale3d(1.4, 1.4, 1.4);
    position: relative !important;
    display: flex !important;
    @include mediumIcon;
}

.custom-icon3 {
    font-size: 24px !important;
    line-height: 26px !important;
    @include mediumIcon
}

.update-btn {
    background: unset !important;
    color: $white !important;

    &:hover {
        color: $white-bg-btn !important;
    }

    &[disabled] {
        background-color: transparent !important;
        color: $white !important;

        &:hover {
            background-color: transparent !important;
            color: $white !important;
        }
    }
}

// .json-btn { 
//     .json-btn-icon{
//         background: url('../../../assets/images/icon/json-file-icon-hover.svg') no-repeat 2px 0;
//         height: 18px;
//         width: 18px;
//         float: left;
//         margin-right: 5px;
//         margin-top: 5px;
//     }
//     &:hover {
//         .json-btn-icon{
//             background: url('../../../assets/images/icon/json-file-icon.svg') no-repeat;
//         }
//     }

//     &[disabled] {
//         .json-btn-icon{
//             background: url('../../../assets/images/icon/json-file-icon-hover.svg') no-repeat;
//         }
//         &:hover {
//             .json-btn-icon{
//                 background: url('../../../assets/images/icon/json-file-icon-hover.svg') no-repeat;
//             }
//         }
//     }
// }

.save-btn {
    color: $primary-btn-2 !important;

    &:hover {
        background-color: $primary-btn-2 !important;
        color: $primary-btn-hover !important;
    }
}

.delete-btn {
    color: $danger-btn !important;

    &:hover {
        background-color: $danger-btn !important;
        color: $primary-btn-hover !important;
    }
}

.refresh-btn {
    @include xxlargeIcon;
    @include border-radius(5px !important);

    &:hover {
        background-color: $primary-btn !important;
        color: $white-text !important;
    }
}

.btn-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted $black;

    .btn-tooltiptext {
        font-family: 'Poppins' !important;
        font-size: small;
        visibility: hidden;
        color: $black-text;
        text-align: center;
        padding: 4px 13px 2px 13px;
        position: absolute;
        z-index: 1;
        border: 1px solid rgba(0, 0, 0, 0.8);
        background: $bg-white;
        height: 25px;
        text-align: center !important;
    }

    &:hover {
        .btn-tooltiptext {
            margin-top: 20px;
            margin-left: 15px;
            box-shadow: -1px -1px rgba(0, 0, 0, 0.2);
            visibility: visible;
            transition-delay: 0.7s;
        }
    }
}

.alert-icon {
    font-size: 25px;
    margin-top: -2px;
    margin-left: -8px;
}

.alert-icon-cancel {
    font-size: 25px;
}

.file_compare_icon {
    &.btn {
        &:disabled {
            opacity: 0.45 !important;
        }

        &:hover {
            opacity: 1;
        }

        opacity: 0.75;
    }

    // img{
    //     background: url("../../../assets/images/icon/file_compare.png") no-repeat;
    //     @include xlargeIcon;
    // }
}

.icon_info,
.icon_success,
.icon_warning,
.icon_error {
    border-radius: 50px;
    display: inline-block;
    margin-left: 5px;
}

.icon_info,
.icon_error,
.icon_success,
.icon_warning {
    @include padding(5px, 10px);
}

.icon_info {
    color: #00529B;
    background-color: #BDE5F8;
}

.icon_success {
    color: #4F8A10;
    background-color: #DFF2BF;
}

.icon_warning {
    color: #9F6000;
    background-color: #FEEFB3;
}

.icon_error {
    color: #D8000C;
    background-color: #FFD2D2;
}

.info_clr {
    color: #00529B;
}

.success_clr {
    color: #6DBD7A;
}

.in_progress_clr {
    color: #EAB676;
}

.failed_clr {
    color: #e43d35;
}

.not_started {
    color: #464545;
}

.success i,
.icon_warning i,
.icon_info i,
.icon_error i_clr {
    font-size: 16px;
}


// 
.du-btn {
    padding: 0px 5px;
    border-radius: 5px !important;
    display: inline-block;
    border: 0 !important;
    font-weight: 600;
    height: 33px;
    font-size: 14px !important;

    &[disabled],
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    .du-btn-text {
        float: right;
        margin: 6px 7px 0 0px;
    }

    .mat-icon {
        font-size: 17px !important;
        line-height: 23px !important;
        float: left
    }

    .fa-icon {
        font-size: 17px !important;
        line-height: 23px !important;
        float: left;
        margin: 0 6px;

        &.fa-expand,
        &.fa-compress {
            font-size: 15px !important;
        }
    }
}

.du-add-btn {
    padding: 0px 5px 3px !important;
    background: $du-add-btn-clr;
    border: 1px solid $du-add-btn-clr !important;
    color: $white !important;

    .mat-icon {
        font-weight: 700;
    }

    &:hover,
    &.active {
        background: $white !important;
        border: 1px solid $du-add-btn-clr !important;
        color: $du-add-btn-clr !important;
    }

    &[disabled],
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;

        &:hover {
            background: $du-add-btn-clr !important;
            border: 1px solid $du-add-btn-clr !important;
            color: $white !important;
        }
    }
}

.du-export-btn {
    padding: 0px 5px 3px !important;
    background: $du-export-btn-clr !important;
    border: 1px solid $du-export-btn-clr !important;
    color: $white !important;

    .fa-icon {
        padding-top: 2px;
    }

    &:hover,
    &.active {
        background: $white !important;
        border: 1px solid $du-export-btn-clr !important;
        color: $du-export-btn-clr !important;
    }

    &[disabled],
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;

        &:hover {
            background: $du-export-btn-clr !important;
            border: 1px solid $du-export-btn-clr !important;
            color: $white !important;
        }
    }
}

.du-confirm-btn {
    padding: 0px 5px 3px !important;
    background:  #1363df !important;
    border: 1px solid #1363df !important;
    color: $white !important;

    .fa-icon {
        padding-top: 2px;
    }

    &:hover,
    &.active {
        background: $white !important;
        border: 1px solid #1363df !important;
        color: $du-save-btn-clr !important;
    }

    &[disabled],
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;

        &:hover {
            background: $du-save-btn-clr !important;
            border: 1px solid $du-save-btn-clr !important;
            color: $white !important;
        }
    }
}

.du-cancel-btn {
    padding: 0px 5px 3px !important;
    background: $du-cancel-btn-clr !important;
    border: 1px solid $du-cancel-btn-clr !important;
    color: $du-cancel-btn-text-clr !important;

    .fa-icon {
        padding-top: 2px;
    }

    &:hover,
    &.active {
        background: $du-cancel-btn-text-clr !important;
        border: 1px solid $du-cancel-btn-text-clr !important;
        color: $du-cancel-btn-clr !important;
    }

    &[disabled],
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;

        &:hover {
            background: $du-cancel-btn-clr !important;
            border: 1px solid $du-cancel-btn-clr !important;
            color: $du-cancel-btn-text-clr !important;
        }
    }
}

.du-warning-btn {
    padding: 0px 5px 3px !important;
    background: $du-delete-btn-clr !important;
    border: 1px solid $du-delete-btn-clr !important;
    color: $white !important;

    .fa-icon {
        padding-top: 2px;
        font-size: 15px !important;
        line-height: 25px !important;
    }

    &:hover,
    &.active {
        background: $white !important;
        border: 1px solid $du-delete-btn-clr !important;
        color: $du-delete-btn-clr !important;
    }

    &[disabled],
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;

        &:hover {
            background: $du-delete-btn-clr !important;
            border: 1px solid $du-delete-btn-clr !important;
            color: $white !important;
        }
    }
}

.du-custom-confirm-btn {
    padding: 0px 5px !important;
    background: $du-confirm-btn-clr;
    border: 1px solid $du-confirm-btn-clr !important;
    color: $white !important;

    .mat-icon {
        font-weight: 700;
    }

    &:hover,
    &.active {
        background: $white !important;
        border: 1px solid $du-confirm-btn-clr !important;
        color: $du-confirm-btn-clr !important;
    }

    &[disabled],
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;

        &:hover {
            background: $du-confirm-btn-clr !important;
            border: 1px solid $du-confirm-btn-clr !important;
            color: $white !important;
        }
    }
}

.du-btn-tab-group {
    .du-tab-btn {
        background: $white !important;
        border: 1px solid $du-primary-clr !important;
        color: $du-primary-clr !important;
        border-radius: 0px !important;

        &:first-child {
            border-radius: 3px 0 0 3px !important;
        }

        &:last-child {
            border-radius: 0 3px 3px 0 !important;
        }

        &.active,
        &:hover {
            background: $du-primary-clr !important;
            border: 1px solid $du-primary-clr !important;
            color: $white !important;
        }
    }
}