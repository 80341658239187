
@mixin ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin xtrasmallIcon {
    height: 12px;
    width: 12px;
}

@mixin xxsmallIcon {
    height: 14px;
    width: 14px;
}

@mixin xsmallIcon {
    height: 16px;
    width: 16px;
}

@mixin ssmallIcon {
    height: 18px;
    width: 18px;
}

@mixin smallIcon {
    height: 20px;
    width: 20px;
}

@mixin sMediumIcon {
    height: 22px;
    width: 22px;
}

@mixin mediumIcon {
    height: 24px;
    width: 24px;
}

@mixin mLargeIcon {
    height: 26px;
    width: 26px;
}

@mixin largeIcon {
    height: 28px;
    width: 28px;
}

@mixin llargeIcon {
    height: 30px;
    width: 30px;
}

@mixin xlargeIcon {
    height: 32px;
    width: 32px;
}

@mixin xmlargeIcon {
    height: 36px;
    width: 36px;
}

@mixin xxlargeIcon {
    height: 40px;
    width: 40px;
}

@mixin xxxlargeIcon {
    height: 46px;
    width: 46px;
}

@mixin disableItem {
    opacity: 0.4;
    // pointer-events: none;
    cursor: not-allowed;
}

@mixin clearfix {
    clear: both;
}

@mixin icon-bg {
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
}

@mixin d-flex-center {
    display: flex;
    align-items: center;
}

@mixin background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

@mixin c-pointer{
    cursor: pointer;
}

//font-size
@mixin font-size($pixels) {
    font-size: $pixels;
}

// Border Radius
@mixin border-radius($pixels) {
    border-radius: $pixels;
}


// PADDING
@mixin padding($yPixels, $xPixels) {
    padding: $yPixels $xPixels;
}

@mixin padding-x($pixels) {
    padding-left: $pixels;
    padding-right: $pixels;
}

@mixin padding-y($pixels) {
    padding-top: $pixels;
    padding-bottom: $pixels;
}

// MARGIN
@mixin margin($yPixels, $xPixels) {
    margin: $yPixels $xPixels;
}

@mixin margin-x($pixels) {
    margin-left: $pixels;
    margin-right: $pixels;
}

@mixin margin-y($pixels) {
    margin-top: $pixels;
    margin-bottom: $pixels;
}