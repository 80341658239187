.mat-select, 
.mat-badge-content,
.mat-button-toggle,
.mat-card,
.mat-checkbox,
.mat-table,
.mat-form-field,
.mat-menu-item,
.mat-select-trigger,
.mat-radio-button,
.mat-select,
.mat-tab-group,
.mat-tab-label,
.mat-tab-link,
.mat-tooltip,
.mat-list-item,
.mat-list-option,
.mat-simple-snackbar,
.mat-tree
{
    font-family: 'Poppins' !important;
}