
//Font Family
$font-Segoe-UI: 'Poppins';
$font-Open-Sans: 'Poppins';
$xsmall-font-size: 8px;
$small-font-size: 10px;
$default-font-size: 12px;
$smedium-font-size: 13px;
$medium-font-size: 14px;
$xmedium-font-size: 16px;
$xlarge-font-size: 18px;
$xxlarge-font-size: 20px;
$xxxlarge-font-size: 24px;

//font Weight
$font-weight-bold4: 400;
$font-weight-bold5: 500;
$font-weight-bold6: 600;
$font-weight-bold7: 700;

//Body Bg 
$bg-white: #fefefe;
$bg-black: #000000;

$white: #fff;
$black: #000;

//Colors:
$white-text: #fff;
$black-text: #000;
$light-white: #f3f3f3;
$grey: #808080;
$dark-grey: #a9a9a9;
$primary-color: #0c2c7c;
$primary-color-hover: #4a6f8a;
$error-bg: #e0c1c2;
$error-clr: #924344;
$info-clr: #68C2D0;
$success-clr: #49AF41;

$primary-blue-clr: #4473C5;
$primary-blue-clr2: #3F51B5;
//button css
$white-bg-btn: #ffffff;
$primary-btn: #1B7AF2;
$primary-btn-2: #3F51B5;
$primary-btn-hover: #ffffff;
$primary-btn-disabled: #ebebeb;
$danger-btn: #F44336;
$disabled-btn: #F44336;
$refresh-icon-clr: #56a8f7;
$rename-icon-clr: #2199e8;
$input-bdr: #ebebeb;
$skip-step-color: #868686;

$du-primary-clr: #49619D;
$du-add-btn-clr: #58B19F;
$du-save-btn-clr: #58B19F;
$du-clone-btn-clr: #59AEDB;
$du-delete-btn-clr: #CF6A87;
$du-nextPrev-btn-clr: #EAB676;
$du-export-btn-clr: #59AEDB;
$du-cancel-btn-text-clr: #626365;
$du-cancel-btn-clr: #D1D8E0;
$du-confirm-btn-clr: #1DD1A1;

$du-dark-grey-clr: #414e59;
$du-light-grey-clr: #D1D8E0;
$du-badge-clr: #82589F;
//Button Colors:
$du-group-btn-bg: #414e59;

$title-clr: #2C3A47;

//Scrollbar color
$scrollbar-bg-color: #a9a9a9;

//Swal Model
$du-model-alert-header-bg: #596275;
$du-model-error-header-bg: #CF6A87;

//model
$model-header-bg: #3b7ff5;
$model-warning-header-bg: #CF6A87;

//gridster report dashboard
$gridster-bg: #f5f5f5;
$widget-header-bg: #dfdfdf;
$model-warning-header-bg: #CF6A87;
