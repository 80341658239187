/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
// @import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css';

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import 'app/scss/base';

@import "@simonwep/pickr/dist/themes/nano.min.css";

@import "../node_modules/angular-resize-element/bundles/style.scss";
html,
body {
  height: 100%;
  font-family: 'Poppins' !important;
}

*:not(mat-icon, .ag-icon, .material-icons, .fa, i, .ag-checkbox-input, .ag-checkbox-input::after) {
  font-family: 'Poppins' !important;
}

body {
  margin: 0;
  overflow: hidden !important;
  font-family: 'Poppins' !important;
  // font-family: "Roboto", "Helvetica Neue", sans-serif, 'Poppins';
}

.logo {
  background-image: url('https://www.dataunveil.com/assets/img/logo.png');
  max-width: 80%;
  min-height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
}

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}


.page-header {
  flex: 0 0 auto;
  background-color: #dcdcdc;
}

.screen-title {
  font-size: 35px;
  font-weight: bolder;
  padding: 27px 20px 11px 15px
}

.page-content {
  flex: 1 1 auto;
  position: relative;
  /* need this to position inner content */
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.page-footer {
  flex: 0 0 auto;
  height: auto;
  text-align: center;
  margin-bottom: -14px;
  // background-color: #dcdcdc;
}

/* PAGE Title */
.page-title,
.page-title:active,
.page-title:link {
  font-weight: bold;
  text-transform: capitalize;
  text-decoration: none;
  font-variant: small-caps;
  color: var(--nav_list_active);
  font-size: 25px !important;
  text-align: center;
  margin-top: 20px;
}


.close-button {
  float: right;
  top: -24px;
  right: -24px;
}

.close-icon {
  transition: 1s ease-in-out;
}

.close-icon:hover {
  transform: rotate(180deg);
}

::ng-deep .icon-outside .close-button {
  float: right;
  top: -52px;
  right: -52px;
}

// .form-control {
//   border:none !important;
//   box-shadow: none !important;
// }

.display-flex {
  display: flex;
}

.mat-cart-content {
  margin-top: 15px;
}

.input-icon {
  position: absolute;
  left: 3px;
  top: calc(50% - 0.5em);
  /* Keep icon in center of input, regardless of the input height */
}

.parent {
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

*:focus {
  outline: none;
}

.mat-dialog-container {
  padding: 0px !important;
  overflow: hidden !important;
  overflow: visible !important;
  border-radius: 10px !important;
}


.cdk-overlay-container {
  position: fixed;
  z-index: 1102;
}

.container .multiselect-dropdown .dropdown-btn {
  border: none !important;
}

.mat-tab-body-content {
  height: 100% !important;
  overflow: hidden !important;
}
.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: none !important;
}

.filterLabelStyle {
  font-family: 'Poppins';
    font-style: normal;
    // letter-spacing: 1px;
    font-weight: 600;
    font-size: 13px;
    // line-height: 27px;
    color: #101D42;
}


.grid-content {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  // height: calc(100vh - 150px);
  margin-bottom: 20px !important;
}


.scrollVertical {
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

@import "~@ng-select/ng-select/themes/default.theme.css";

hr {
  margin: 3px 0px 6px 0px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-select-value-text {
  margin-left: 9px !important;
}

.groupOfCss > .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  padding: 0;
  min-height: 60px;
  height: auto;
  margin-right: -18px;
  opacity: 1 !important;
}

.groupOfCss > .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels {
  
  #mat-tab-label-1-0 ,#mat-tab-label-0-0 {
    z-index: 999 !important;
  }

  #mat-tab-label-1-1, #mat-tab-label-0-1 {
    z-index: 998 !important;
  }

  #mat-tab-label-1-2, #mat-tab-label-0-2 {
    z-index: 997 !important;
  }

  #mat-tab-label-1-3, #mat-tab-label-0-3 {
    z-index: 996 !important;
  }

  #mat-tab-label-1-4, #mat-tab-label-0-4 {
    z-index: 995 !important;
  }
  #mat-tab-label-1-5, #mat-tab-label-0-5 {
    z-index: 994 !important;
  }
  #mat-tab-label-1-6, #mat-tab-label-0-6 {
    z-index: 993 !important;
  }
  #mat-tab-label-1-7, #mat-tab-label-0-7 {
    z-index: 992 !important;
  }
  #mat-tab-label-1-8, #mat-tab-label-0-8 {
    z-index: 991 !important;
  }
  #mat-tab-label-1-9, #mat-tab-label-0-9 {
    z-index: 990 !important;
  }
  #mat-tab-label-1-10, #mat-tab-label-0-10 {
    z-index: 989 !important;
  }
} 

.groupOfCss > .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-ink-bar{
  background-color: #1363df !important;
  width: 100px !important;
  margin-left: 50px !important;
  bottom: 6px;
  height: 2px;
  z-index: 9999 !important;
  content-visibility: auto;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  overflow-x: hidden !important;
  cursor:grab;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1363DF  ;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1363DF;
}
html, body { height: 100%; }
body { margin: 0; font-family: 'Poppins'; }
// * { 
//   font-family: 'Poppins'!important;
// }
// html, body { height: auto;  overflow: auto;}


// pdf Css
.color-picker-container {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.color-picker-container > [name="odd-bkg-color"],
.color-picker-container > [name="even-bkg-color"] {
  margin-right: 5px;
}
.color-picker-container > [name="odd-bkg-color"] button,
.color-picker-container > [name="even-bkg-color"] button {
  border: 2px solid grey;
}

.form-wrap {
  display: flex;
  // border: 4px solid grey;
  border-radius: 5px;
  // margin: 10px auto;
  max-width: 800px;
}
.form-wrap form {
  padding: 10px;
}

.form-wrap > grid-options-panel,
.form-wrap > pdf-export-panel {
  flex: 1;
}

.form-wrap form button {
  display: block;
  margin-left: auto;
}

input[type="radio"] {
  margin-right: 5px;
}

input[type="radio"] + label {
  margin-right: 34px;
}
.bg-text {
  font-size: 1.6vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-family: 'Poppins';
}

 // Success Message Class
 .success-class {
  background-color: green;
  color: #ffff;
  font-size: medium;
  font-weight: 400;
  font-family:"Poppins" !important;
  text-align: center !important;
}
// Error Message Class
.error-class {
  background-color: red;
  color: #ffff;
  font-size: medium;
  font-weight: 400;
  font-family:"Poppins" !important;
  text-align: center !important;
}
// Warning Message Class
.warning-class {
  background-color: rgba(249, 249, 14, 0.663);
  color: black;
  font-size: medium;
  font-weight: 500;
  font-family:"Poppins" !important;
  text-align: center !important;
  // border-radius: 10px !important;
  // border: 1px solid purple;
}

.confirmBtnHomePopups {
  background-color: rgb(29, 211, 162) !important;
  color: #fff;
  font-size: medium;
  font-weight: 400;
  font-family:"Poppins" !important;
  text-align: center !important;
  border: none !important;
}
.confirmBtnHomePopups:focus {
  box-shadow: 0 0 0 0.2rem rgba(29,211,162,.5);
}
.confirmBtnHomePopups:active {
  box-shadow: 0 0 0 0.2rem rgba(29,211,162,.5);
}

::ng-deep .ag-theme-alpine-dark .ag-menu.ag-popup-child,
.ag-theme-balham .ag-menu.ag-popup-child,
.ag-theme-alpine .ag-menu.ag-popup-child {
  background-color: #fff !important;
  border-radius: 10px !important;
  font-family: 'Poppins' !important;
}

::ng-deep .ag-theme-alpine-dark.ag-popup {
  background-color: #fff !important;
  border-radius: 10px !important;
  font-family: 'Poppins' !important;
}

::ng-deep .ag-popup .ag-ltr.ag-popup-child{
  background-color: #fff !important;
  border: 1px solid grey; 
}